import angular from 'angular';
import { SharedAngular } from '@Client/@types/sharedAngular';
import StepService, {
  IFormValidationResult
} from '@Client/runner.services/step.service';
import { FormGen } from '@Client/@types/formGen';
import { IEventFileUploadResult } from '@Shared.Angular/@types/pubSub';
import { FormFieldType } from '@Shared.Angular/flowingly.services/flowingly.constants';
import IApiPublicFormModel from '@Shared.Angular/@types/core/contracts/queryModel/api/apiPublicFormModel';
import { IRunnerApiPublicFormModel } from '@Client/runner.services/runner.public-form.service';
import IFormattedStepSchema from '@Client/interfaces/IFormattedStepSchema';

class RunnerPublicFormController {
  static $inject = [
    '$scope',
    '$stateParams',
    'momentService',
    'runnerPublicFormService',
    'notificationService',
    'guidService',
    '$state',
    'redirectService',
    'runnerCardService',
    'stepService',
    'appInsightsService',
    'pubsubService',
    'fgFileListService',
    'APP_CONFIG'
  ];

  private defaultBgColor = '#eeeeee';
  private backgroundColour = this.defaultBgColor;
  private formData: IApiPublicFormModel;
  private data;
  private showForm = true;
  private submitSuccess = false;
  private errorGetForm = false;
  private stepId = null;
  private isLoading = true;
  private submitRequestPending = false;
  private subscriberId = 'publicFormController';
  constructor(
    private $scope,
    private $stateParams,
    private momentService,
    private runnerPublicFormService: RunnerPublicFormService,
    private notificationService,
    private guidService,
    private $state,
    private redirectService,
    private runnerCardService,
    private stepService: StepService,
    private appInsightsService: SharedAngular.AppInsightsService,
    private pubsubService: SharedAngular.PubSubService,
    private fgFileListService: FormGen.FgFileListService,
    private APP_CONFIG: SharedAngular.APP_CONFIG
  ) {}

  $onInit() {
    const errorHandler = (err) => {
      this.resetFlag();
      if (err) {
        if (err.status === 401) {
          // user doesn't have permission to view the public form
          this.redirectService.setPendingRequestAsUrl(
            `/form/${this.$stateParams.id}`
          );
          this.$state.go('app.login');
        }
      } else {
        this.errorGetForm = true;
        this.isLoading = false;
      }
    };
    this.stepId = this.guidService.new();
    this.pubsubService.subscribe(
      'FILEUPLOAD_FILE_ERROR',
      (event, data: IEventFileUploadResult) => {
        this.fgFileListService.setFileListInvalid(
          data.fileControlId,
          this.stepId,
          undefined,
          true
        );
      },
      this.subscriberId
    );
    this.pubsubService.subscribe(
      'FILEUPLOAD_FILE_VALID',
      (event, data: IEventFileUploadResult) => {
        this.fgFileListService.setFileListInvalid(
          data.fileControlId,
          this.stepId,
          undefined,
          false
        );
      },
      this.subscriberId
    );

    if (this.$stateParams.isExternalForm) {
      this.stepId = this.$stateParams.stepId;
    }

    const getFormPromise = this.$stateParams.isExternalForm
      ? this.runnerPublicFormService.getExternalFormByStepId(
          this.$stateParams.stepId
        )
      : this.runnerPublicFormService.getFormById(this.$stateParams.id);

    getFormPromise.then(
      (data) => {
        if (data === undefined) {
          errorHandler(null);
        } else {
          angular.element('.loading').removeClass('loading');
          this.isLoading = false;
          this.formData = data;
          // TODO once switchover to new infrastructure is complete we can update
          // the brand logo setting instead of modifying it
          const legacyPath = '/Client/dist/';
          if (
            this.formData.brandLogo &&
            this.formData.brandLogo.indexOf(legacyPath) > -1
          ) {
            this.formData.brandLogo =
              this.formData.brandLogo.split(legacyPath)[1];
          }
          this.backgroundColour =
            this.formData.backgroundColor &&
            this.formData.backgroundColor !== ''
              ? this.formData.backgroundColor
              : this.defaultBgColor;
          angular
            .element('.public-form')
            .css('background-color', this.backgroundColour);
          this.initialiseFormData(this.formData);
        }
      },
      (err: any) => {
        errorHandler(err);
      }
    );
  }

  $onDestroy() {
    this.pubsubService.unsubscribeAll(this.subscriberId);
  }

  saveForm(data, schema) {
    if (!this.$stateParams.isExternalForm) {
      return;
    }
    const step = { $data: data, Schema: schema };
    const parsedData = this.stepService.getFormDataWithoutValidating(step);

    this.runnerPublicFormService.saveForm(this.$stateParams.stepId, parsedData);
  }

  submitForm(data, schema) {
    this.appInsightsService.startEventTimer('publicFormSubmitted');
    this.submitRequestPending = true;
    const fileUploadData = this.runnerCardService.createFileUploadData(
      this.stepId,
      schema
    );
    data = this.runnerCardService.formatFieldsWithCustomDBUserTypeToObject(
      data,
      schema
    );
    const validationResult = this.stepService.validateForm(
      undefined,
      data,
      schema,
      this.$scope.publicForm,
      fileUploadData,
      true,
      this.stepId
    );
    const isValid = this.stepService.validationResultIsGood(validationResult);
    if (!isValid || !validationResult.form.$valid) {
      this.notificationService.showErrorToast(
        'Oops, a validation error has occurred, please review the fields on the current step for more details.'
      );
      this.submitRequestPending = false;
      this.pubsubService.publish('CLEAR_CAPTCHA');
      return;
    }

    const {
      publicFormId,
      stepName,
      businessId: publicFormBusinessId,
      userId: publicFormUserId
    } = this.formData;

    const submitFormPromise = this.$stateParams.isExternalForm
      ? this.runnerPublicFormService.submitExternalForm(
          this.$stateParams.stepId,
          this.removeFileFieldFromFormData(validationResult, schema)
        )
      : this.runnerPublicFormService.submitForm(
          this.$stateParams.id,
          publicFormId,
          validationResult.formData,
          this.stepId
        );

    submitFormPromise
      .then(() => {
        this.submitRequestPending = false;
        this.resetFlag();
        this.submitSuccess = true;
      })
      .catch(() => {
        this.submitRequestPending = false;
        this.notificationService.showErrorToast('Form submit failed');
        this.pubsubService.publish('CLEAR_CAPTCHA');
      })
      .finally(() => {
        this.appInsightsService.trackMetricIfTimerExist('publicFormSubmitted', {
          publicFormId,
          stepName,
          publicFormBusinessId,
          publicFormUserId
        });
      });
  }

  resetFlag() {
    this.showForm = false;
    this.submitSuccess = false;
    this.errorGetForm = false;
  }

  initialiseFormData(publicForm: IRunnerApiPublicFormModel) {
    const schemaFields = publicForm.schema.fields;
    const fields = publicForm.fields;

    for (const f of schemaFields) {
      const matchField = fields.find((field) => field.name === f.name);
      let parsedValue = undefined;

      if (matchField) {
        switch (f.type.toLowerCase()) {
          case FormFieldType.DATE:
            if (f.defaultValueOption === 'autoPopulate')
              parsedValue = this.momentService
                .utc(new Date())
                .local()
                .format('DD/MM/YYYY');
            break;
          case FormFieldType.DATETIME:
            if (f.defaultValueOption === 'autoPopulate')
              parsedValue = this.momentService
                .utc(new Date())
                .local()
                .format('DD/MM/YYYY h:mm:ss A'); // as in datetime kendo ui format
            break;
          case FormFieldType.TABLE:
            f.lookupValues = matchField.lookupValues?.map((lv) => {
              return {
                ColumnId: lv.columnId,
                Value: lv.value
              };
            });
            parsedValue = matchField.value;
            break;
          default:
            parsedValue = matchField.value;
            break;
        }

        fields[f.name] = parsedValue;
      }
    }
    if (this.$stateParams?.isExternalForm) {
      this.initialiseExternalFormData(publicForm, fields);
    }
  }

  initialiseExternalFormData = (publicForm, fields) => {
    this.$stateParams.id = publicForm.flowModelId;

    publicForm.schema.fields.forEach((field) => {
      const matchField = fields.find((f) => f.name === field.name);

      if (matchField) {
        this.setFieldValue(field, matchField);
      }
    });
  };

  setFieldValue = (field, matchField) => {
    switch (field.type.toLowerCase()) {
      case FormFieldType.MULTISELECT_LIST:
      case FormFieldType.TASK_LIST:
        field.value = this.parseMultiSelectOrTaskList(field, matchField);
        break;
      case FormFieldType.FILE_UPLOAD:
        field.text = this.parseFileUpload(field, matchField);
        field.value = field.text;
        break;
      case FormFieldType.CHECKBOX:
        field.value = matchField.value === 'true';
        break;
      case FormFieldType.NUMBER:
        field.value = parseFloat(matchField.value);
        break;
      default:
        field.value = matchField.value;
        break;
    }
  };

  parseMultiSelectOrTaskList = (field, matchField) => {
    const parsedValue = JSON.parse(matchField.value);
    if (!parsedValue) {
      return null;
    }
    return parsedValue.reduce((acc, { key, value }) => {
      const option = field.options.find((x) => x.text === key);
      if (option) {
        acc[option.value] = value === 'true';
      }
      return acc;
    }, {});
  };

  parseFileUpload = (field, matchField) => {
    const files = JSON.parse(matchField.text);
    if (!files) {
      return;
    }
    return files?.map((file) => ({
      ...file,
      filepath: this.generateLink(this.$stateParams.stepId, file.id),
      downloadLink: this.generateDownloadLink(file.id),
      isAuthRequired: false
    }));
  };

  generateLink(stepId, fileId) {
    return fileId !== undefined
      ? `${this.APP_CONFIG.apiBaseUrl}form/steps/${stepId}/files/${fileId}`
      : undefined;
  }
  generateDownloadLink(fileId) {
    return fileId !== undefined
      ? `${this.APP_CONFIG.apiBaseUrl}files/downloadpublic/${fileId}`
      : undefined;
  }

  removeFileFieldFromFormData(
    validationResult: IFormValidationResult,
    schema: IFormattedStepSchema
  ) {
    const fileUploadFields = schema.fields
      .filter((field) => field.type.toLowerCase() === FormFieldType.FILE_UPLOAD)
      .map((field) => field.name);

    validationResult.formData.forEach((field) => {
      if (fileUploadFields.includes(field.key)) {
        field.value = null;
      }
    });

    return validationResult.formData;
  }
}

angular
  .module('flowingly.runner.public-form')
  .controller('runnerPublicFormController', RunnerPublicFormController);
